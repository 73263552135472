import { createStore } from "vuex";

export default createStore({
  state: {
    submit:false,
    productDetail:{}, //产品信息
  },
  mutations: {},
  actions: {},
  modules: {},
});
