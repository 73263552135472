import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    redirect:'/home',
    component: () => import('@/views/index.vue'),
    children:[
      {
        path:"/home",
        name:'仰望科技',
        component: () => import('@/views/index/home.vue'),
        meta:{
          title: '仰望科技'
        },
      },
      {
        path:"/product",
        name:'产品列表',
        redirect:'/product/page1',
        component: () => import('@/views/product/index.vue'),
        meta:{
          title: '产品列表'
        },
        children:[
          {
            path:"/product/page1",
            name:'产品列表',
            component: () => import('@/views/product/page1.vue'),
          },
          {
            path:"/product/pageDetail",
            name:'产品详情',
            component: () => import('@/views/product/pageDetail.vue'),
          },
        ],
      },
      {
        path:"/product/parameter",
        name:'技术参数',
        component: () => import('@/views/product/parameter.vue'),
      },
      {
        path:"/industry",
        name:'行业应用',
        redirect:'/industry/page1',
        meta:{title: '行业应用'},
        children:[
          {
            path:"/industry/page1",
            name:'工业应用',
            component: () => import('@/views/industry/index.vue'),
          },
          {
            path:"/industry/page2",
            name:'显微镜应用',
            component: () => import('@/views/industry/index.vue'),
          },
          {
            path:"/industry/page3",
            name:'科学研究',
            component: () => import('@/views/industry/index.vue'),
          },
          {
            path:"/industry/page4",
            name:'教学研究',
            component: () => import('@/views/industry/index.vue'),
          },
        ],
      },
      {
        path:"/serve",
        name:'服务支持',
        redirect:'/serve/qusetion',
        component: () => import('@/views/serve/index.vue'),
        meta:{
          title: '服务支持'
        },
        children:[
          {
            path:"/serve/qusetion",
            name:'常见问题',
            component: () => import('@/views/serve/qusetion.vue'),
          },
          {
            path:"/serve/resourceDownload",
            name:'资源下载',
            component: () => import('@/views/serve/resourceDownload.vue'),
          },
        ],
      },
      {
        path:"/aboutUs/index",
        name:'关于',
        meta:{title: '关于'},
        component: () => import('@/views/aboutUs/index.vue'),
      },
      {
        path:"/aboutUs/culture",
        meta:{title: '企业文化'},
        name:'企业文化',
        component: () => import('@/views/aboutUs/culture.vue'),
      },
      {
        path:"/aboutUs/technology",
        meta:{title: '核心技术'},
        name:'核心技术',
        component: () => import('@/views/aboutUs/technology.vue'),
      },
      {
        path:"/news",
        name:'新闻',
        component: () => import('@/views/new/index.vue'),
        redirect:'/news/enterprise',
        meta:{title: '新闻'},
        children:[
          {
            path:"/news/enterprise",
            name:'关于我们',
            component: () => import('@/views/new/enterprise.vue'),
          },
          {
            path:"/news/detail",
            name:'新闻详情',
            component: () => import('@/views/new/detail.vue'),
          },
        ],
      },
    ]

  },
];

const router = createRouter({
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
