export default {
    navbarList:['首页',"产品中心","行业应用","服务支持","关于我们"],
    search:'搜索',
    questionTitle:'常见问题',
    contactUs:'联系我们',
    indexBannerName:'了解产品',
    indexHotName:'产品新动向',
    indexIndustryName:'行业应用',
    hotProducts:'热门产品',
    productClass:'产品分类',
    companyNews:'企业新闻',
    indexServeTitle:'用科技服务大众 为客户量身定制',
    indexIndustryList:[
        {
            chinese:'科学研究',
            english:'Scientific Research',
        },
        {
            chinese:'显微镜应用',
            english:'Microscope Application',
        },
        {
            chinese:'工业应用',
            english:'Dustrial Application',
        },
        {
            chinese:'教学研究',
            english:'Teaching Research',
        },
    ],
    footerNav:[
        {
          cn:'联系我们',
          child:[
            {
              text:'0755-23200330-8008',type:2,path:'',
            },
            {
              text:'深圳市龙岗区坂田街道雪岗路2018号天安云谷二期4栋1609室',type:3,path:'',
            },
          ],
        },
        // {
        //   cn:'产品与案例',
        //   child:[
        //     {
        //       text:'后台系统管理',type:1,path:'',
        //     },
        //     {
        //       text:'客户端系统',type:1,path:'',
        //     },
        //   ],
        // },
        {
          cn:'服务与支持',
          child:[
            // {
            //   text:'Watch',type:1,path:'',
            // },
            {
              text:'Android Apps',type:1,path:'/serve/resourceDownload',
            },
          ],
        },
        {
          cn:'关于我们',
          child:[
            {
              text:'公司介绍',type:1,path:'/aboutUs/index',
            },
            {
              text:'公司资质',type:3,path:'',
            },
          ],
        },
    ],
    aboutNav:['企业介绍','企业文化','核心技术','企业愿景'],
    serveNav:['软件下载','常见问题'],
    more:'更多产品',
    detail:'了解产品',
    parameterNav:['产品介绍','技术参数'],
    technicalFeatures:'技术参数',
    resourceDownLoad:"资源下载",
    downloadVersion:'版本',
    downloadSize:'大小',
    downloadUpdat:'更新日期',
    download:'下载',
    downloadBannerTitle:'服务支持',
    downloadBannerText:'为您提供高效的服务保障、多样化的服务支持计划让客户跟便捷、更安心',
    questionList:[
      {
          question:'触发时相机没响应或不正常',
          answer:'答：检查软件是否选择正确的触发模式; 检查线材连接是否正确; 检查触发信号电平和极性是否正确。',
      },
      {
          question:'启动相机时，图像颜色有偏差',
          answer:'答：请点击一次白平衡或区域白平衡，或手动调节RGB增益； 请查看是否选择合适的颜色矩阵模式。',
      },
      {
          question:'相机图像不真实',
          answer:'答：请检查白平衡、伽玛、对比度、黑平衡等参数调节是否合适。',
      },
      {
          question:'图像画面会有闪烁或有水波纹一类的现象',
          answer:'答：请确认光源是否稳定，若光源稳定，则启用消频闪功能。',
      },
    ],
    industrysList:[
      {
          module:'工业应用',
          brief:'由于工业相机的性能稳定可靠易于安装，相机结构紧凑结实不易损坏，连续工作时间长，可在较差的环境下使用，快门时间非常短，可以抓拍高速运动的物体，弥补了人力受环境因素的影响。广泛应用于如开发金属材料及树脂材料时，用来观察材料受到冲击时内部裂纹产生的方向、状态等，可用来分析材料被破坏时物质的结构，再如质量检测、工件测量等环节。',
          effect:'被广泛应用于工业领域',
          effectDetail:'机器视觉    工业监视和检测    材料检测    PCB和SMT检测    印刷和包装    纺织物检测    四轮定位    太阳板检测    机器人自动化    运动动态监测',
          achievement:[],
      },
      {
          module:'显微镜应用',
          brief:'生物医疗显微学。我司的机器视觉相机为医疗和生物科学提供色彩鲜明的图像。具有高精度和色彩还原度高的特点，保证高超的显微学性能，颜色逼真，检测和存储可信度高。如果你还在寻找功能强大而构造简单，效果显著而价格低廉的显微相机，那么仰望科技会是一个不错的选择。',
          effect:'显微镜成像 生命科学',
          effectDetail:'',
          achievement:['医疗保健','生物科技','荧光','金相'],
      },
      {
          module:'科学研究',
          brief:'工业相机应用于显微图像采集，并且可对观察物体大小进行测量，对观察图片进行拍照保存等操作。此外，还有皮肤检视.发根检视.纺织检视.生物观察.宝石鉴定.协助视障人士阅读.印刷检视工业检视，精密机械等众多应用。',
          effect:'生命科学领域进一步深入研究和探索',
          effectDetail:'',
          achievement:['生命科学','医疗护理','技术'],
      },
      {
          module:'教学研究',
          brief:'教学研究。即拍即看、操作简单、清晰度高、功能多等特点，用于再现生动实验情景，完整还原实验过程，完整还原实验过程，显微显现与直观反馈，综合信息搜集整理与统计，用以满足电子信息类课程实验教学要求，可以实现图像捕捉、显示以及相关的图像处理。',
          effect:'教学其他应用',
          effectDetail:'',
          achievement:['机器学习','模式识别','三维测量','图像分割','人工智能'],
      },
    ],
    AboutUs:'关于我们',
    aboutUsCopy:'深圳市仰望科技有限公司',
    aboutUsEstablished:'成立于',
    year:'年',
    aboutUsInfo:'坐落于美丽的海滨城市--鹏城深圳，入驻大型甲级写字楼自有产业办公。是一家在智能化设备，多媒体教育等领域积攒了丰富经验的企业，集研发、生产、销售于一体的高科技公司，公司拥有一支高素质的专业研发团队，全身心致力于自主研发与创新，引进了国际市场核心先进技术，研发成效硕果累累。市场覆盖亚洲、欧洲、南美、北美、非洲三十多个主要国家和地区，至今获得了国内外多家知名企业的好评。',
    aboutUsRange:'三十多个主要国家和地区',
    aboutUsBand:'公司自有品牌“仰望者”，主要经营产品有：工业相机、显微镜、一体式超薄电脑、多媒体智能黑板等。近十年来，公司建立以“创新、创誉、创利”为目标的技术体系，以满足客户需求为公司宗旨，视信誉为公司生存之本，立零返件为公司目标，致力于人才的培训和技术的提升。',
    aboutUsSlogan:`公司秉承“对自己，以身作则，全力以赴；对工作，主动担当，高效执行；对同事，尊重包容，感恩奉献；对客户，真诚服务，合作共赢。”的核心价值观，聚焦客户需求与挑战，提供先进的科学仪器及检测设备，用心为客户创造价值。过去的成功经验告诉我们，高品质的产品与优质的服务是我们蓬勃发展的关键。仰望科技期待与各位同行，合作伙伴及用户与我们携手稳步前行，风雨同舟，谱写美丽诗章，共创辉煌蓝图！`,
    cultureTitle:"企业文化",
    cultureBrief:"公司秉承“对自己，以身作则，全力以赴；对工作，主动担当，高效执行；对同事，尊重包容，感恩奉献；对客户，真诚服务，合作共赢”的核心价值观，聚焦客户需求与挑战，提供先进的科学仪器及检测设备，用心为客户创造价值。",
    cultureList:[
      {name:'经营理念',label:'诚信务实 开拓进取',eng:'Integrity and pragmatism'},
      {name:'产品战略',label:'工业级标准 专业化品质 人性化设计',eng:'Industrial Standards Professional Quality Humanized Design'},
      {name:'企业精神',label:'为客户量身定制 用科技服务大众',eng:'Tailored to customers,using technology to serve the publio'},
      {name:'服务宗旨',label:'更好 更新 更及时',eng:'Better update More timely'},
    ],
    technologyName:'核心技术',
    technologyBrief:'仰望科技产品中心为客户提供全面、专业的售前技术方案和产品咨询以及提供快速的售后技术支持服务，仰望科技工业相机已经广泛应用于显微镜、人像、太阳能、工业检测、生物医学、教学、虹膜识别、机器视觉、科学研究等领域。',
    technologyItem1Name:'仰望者系列·工业相机',
    technologyItem1Brief:'采用双ARM架构单芯片设计',
    technologyItem1Desc:'性能可靠稳定，比同行FPGA设计更为简洁，功能更加强大，图像算法经过仰望科技专业工程师10多年行业深耕已处在业界领先水平，传感器均采用日本松下产品。 仰望科技致力于成为中国工业相机的第一品牌。',
    technologyItem2Name:'从usb2.0相机到VGA相机',
    technologyItem2Brief:'从千兆网相机到USB3.0相机',
    technologyItem2Desc:'从CMOS到CCD，从30万像素到1800万像素，从经济型相机到专业型相机，品种丰富，种类齐全。卓越的品质是我们产品最可靠的保证。 我们致力于长期为客户提供高性价比的产品，完善的行业解决方案和一流的售后服务。',
    technologyMore:'了解更多产品',
}