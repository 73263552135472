<template>
  <div id="nav">
    <router-view />
  </div>
</template>

<style lang="scss">
@import '/static/common.scss';
*{
  padding: 0;
  margin:0;
  // -moz-user-select: -moz-none;
  // -moz-user-select: none;
  // -o-user-select: none;
  // -khtml-user-select: none;
  // -webkit-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}
#app {
  font-family: Noto Sans SC;
}
img{
  display: block;
  width: 100%;
  height: 100%;
}

</style>
